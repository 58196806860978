import React from 'react'
import MaterialTable from 'material-table'
import tableIcons from '../../../../tableIcons'
export default function DronesDetailsTable(props) {
    const {data} = props
    return (
        
        <div style={{
            maxWidth: "100%"
        }}>
            <MaterialTable
                icons={tableIcons}
                columns={[
                {
                    title: "Date",
                    field: "date",
                    type: "date"
                },{
                    title: "Time",
                    field: "time",
                    type: "time"
                },{
                    title: "Ping",
                    field: "ping",
                    type: "numeric"
                }, {
                    title: "Download (Mbps)",
                    field: "download",
                    type: "numeric"
                }, {
                    title: "Upload (Mbps)",
                    field: "upload",
                    type: "numeric"
                }
            ]}
                data={data}
                options={{
                search: false,
                toolbar: false,
                pageSizeOptions: [5, 10, 25, 50, 100]
            }}/>
        </div>
    )
}