import React from 'react'
import {Line} from 'react-chartjs-2';

export default function DroneDetailsChartToday(props) {
  const {ping, download, upload, labels} = props
  const tableData = {
    labels: labels,
    datasets: [
      {
        label: 'Ping',
        fill: false,
        lineTension: 0.4,
        borderColor: 'orange',
        pointBorderColor: 'orange',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: ping,
      },
      {
        label: 'Upload',
        fill: false,
        lineTension: 0.4,
        borderColor: 'red',
        pointBorderColor: 'red',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: upload,
      },
      {
        label: 'Download',
        fill: false,
        lineTension: 0.4,
        borderColor: 'green',
        pointBorderColor: 'green',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: download,
      }
    ]
  }
    return (
        <div style={{height: "60vh"}}>
<Line data={tableData}  options={{maintainAspectRatio: false}}/>
        </div>
    )
}
