import React, {useState} from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import {loginStyle, innerLoginStyle, imgStyle, buttonStyle} from "./LoginPage/loginStyles";
import {Typography} from '@material-ui/core';
import LogoutPage from './LogoutPage';
export default function PendingPage(props) {
    const {auth, setAuth} = props
    const [logout,
        setLogout] = useState()
    return (
        <div>
            {logout
                ? <LogoutPage auth={auth} setAuth={setAuth}/>
                : <Paper elevation={3} style={loginStyle}>
                    <Grid container style={innerLoginStyle}>
                        <Grid item xs={4}/>
                        <Grid item xs={4}>
                            <img style={imgStyle} alt="Teleport Logo" src="./logo-HighRes.png"/>
                        </Grid>
                        <Grid item xs={4}/>

                        <Grid item xs={12}>
                            <h1
                                style={{
                                textAlign: "center",
                                fontFamily: "Nunito"
                            }}>Teleport Speedtest</h1>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            style={{
                            textAlign: "center",
                            marginLeft: 10,
                            marginRight: 10
                        }}>
                            <Typography variant="body1">
                                You are currently a "Pending User". This means that currently you cannot access
                                any resouces. However, the admins have been notified and have the ability to
                                give you the correct permissions. If granted, these permissions will be applied
                                on your next login.
                            </Typography>
                            <Button style={buttonStyle} variant="outlined" onClick={() => setLogout(true)}>
                                Logout
                            </Button>

                        </Grid>

                    </Grid>
                </Paper>
}
        </div>
    )
}
