import React, {useState} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import MaterialDrawer from './components/MaterialDrawer'

import HomePage from './components/pages/HomePage/HomePage'
import DronesPage from './components/pages/DronesPage/DronesPage'
import DroneSettingsPage from './components/pages/DroneSettingsPage/DroneSettingsPage'
import UserSettingsPage from './components/pages/UserSettingsPage/UserSettingsPage'
import LoginPage from './components/pages/LoginPage/LoginPage'
import LogoutPage from './components/pages/LogoutPage'
import PendingPage from './components/pages/PendingPage'

import Secured from './components/Secured'

import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
const theme = createMuiTheme({
    palette: {
        primary: {
            500: '#2c89a2'
        }
    },
    typography: {
        fontFamily: "Nunito"
    }
});

export default function App() {
    const [auth,
        setAuth] = useState(null)
    const [permission,
        setPermission] = useState(null)

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route exact path="/login">
                        <LoginPage auth={auth} setAuth={setAuth} permission={permission} setPermission={setPermission}/>
                    </Route>
                    <Route exact path="/pending">
                        <PendingPage auth={auth} setAuth={setAuth}/>
                    </Route>
                    <MaterialDrawer
                        links={{
                        home: "/home",
                        drones: "/drones",
                        droneSettings: "/drone-settings",
                        userSettings: "/user-settings",
                        logout: "/logout"
                    }}
                        auth={auth}
                        setAuth={setAuth}
                        permission={permission}
                        setPermission={setPermission}>

                        <Secured auth={auth}>
                            <Route exact path="/home" component={HomePage}/>
                            <Route exact path="/drones" component={DronesPage}/>
                            <Route exact path="/drone-settings" component={DroneSettingsPage}/>
                            {permission === "admin" ? <Route exact path="/user-settings">
                                <UserSettingsPage auth={auth} permission={permission}/>
                            </Route> : <Redirect to="/home"/>}

                            <Route exact path="/logout">
                                <LogoutPage setAuth={setAuth} auth={auth}/>
                            </Route>
                        </Secured>
                    </MaterialDrawer>
                </Switch>

            </Router>
        </ThemeProvider>
    );
}
