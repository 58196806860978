import app from '../../base'
const deleteUserAdmin = app.functions().httpsCallable('deleteUser')

export const makeMaintainer = uid => {
    app.firestore().collection("users").doc(uid).set({
        permission: "maintainer"
    }, {merge: true})
}

export const makeAdmin = uid => {
    app.firestore().collection("users").doc(uid).set({
        permission: "admin"
    }, {merge: true})
}

export const deleteUser = async(uid) => {
    await deleteUserAdmin({uid: uid})
    await app.firestore().collection("users").doc(uid).delete()
}