import React, {useState, useEffect} from 'react'

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import firebase from 'firebase'

import ErrorBox from '../../ErrorBox'

import app from '../../../base'
import isDroneOnline from '../../../isDroneOnline'

export default function DroneFactoryReset() {
    const [nameList,
        setNameList] = useState([])
    const [selectedDrone,
        setSelectedDrone] = useState("")
    const [error,
        setError] = useState([false, "Title", "Description"])
    const [isLoading,
        setIsLoading] = useState(false)
    const [isDroneFactoryReset,
        setIsDroneFactoryReset] = useState(false)
    const deleteUser = app
        .functions()
        .httpsCallable('deleteUser')

    useEffect(() => {
        const unsubscribe = app
            .firestore()
            .collection("drones")
            .onSnapshot(function (snapshot) {
                snapshot
                    .docChanges()
                    .forEach(function (change) {
                        if (change.type === "added") {
                            if (change.doc.id !== "drone-passwords" && !nameList.includes(change.doc.id)) {
                                setNameList(prev => prev.concat(change.doc.id))
                            }
                        }
                        if (change.type === "removed") {
                            if (change.doc.id === selectedDrone) {
                                setSelectedDrone("")
                            }
                            setNameList(prev => prev.filter(item => item !== change.doc.id))

                        }
                    });
            });

        return () => {
            unsubscribe()
        }

    }, [nameList, setNameList, selectedDrone])

    const handleFactoryReset = () => {
        setIsLoading(true)
        console.log("factory reset: ", selectedDrone)
        const drone = selectedDrone
        setSelectedDrone("")
        isDroneOnline(drone, "operational", state => {
            if (state) {
                const db = app.firestore()
                db
                    .collection("drones")
                    .doc(drone)
                    .set({factoryReset: true}, {merge: true})
                    .then(function () {
                        console.log("factory reset: true");
                        deleteUser({uid: drone}).then(() => {
                            setIsLoading(false)
                            setIsDroneFactoryReset(true)
                            setTimeout(() => {
                                setIsDroneFactoryReset(false)
                            }, 3000);
                        })
                    })
                    .catch(function (error) {
                        console.error("Error updating document: ", error);
                        setError([true, "Factory Reset Error", "An error occured factory reseting the drone"])
                        setIsLoading(false)
                    });
                db
                    .collection("drones")
                    .doc("drone-passwords")
                    .update({
                        [drone]: firebase
                            .firestore
                            .FieldValue
                            .delete()
                    })
            } else {
                setError([true, "Drone Offline", "The drone is offline and thus cannot be factory reset."])
                setIsLoading(false)
                setSelectedDrone("")

            }
        })

    }

    const handleChange = (event) => {
        setSelectedDrone(event.target.value)
    }

    return (
        <div>
            {error[0] && <ErrorBox title={error[1]} text={error[2]} setError={setError}/>}
            <Typography
                style={{
                textAlign: "center"
            }}
                variant="h4">Drone Factory Reset</Typography>

            <div style={{
                textAlign: "center"
            }}>
                Select the name of the drone you want to factory reset<br/>THIS ACTION CANNOT BE UNDONE<br/><br/> {isDroneFactoryReset
                    ? <h2>Drone successfully factory reset</h2>
                    : isLoading
                        ? <CircularProgress size={50}/>
                        : <FormControl
                            variant="outlined"
                            style={{
                            minWidth: 140
                        }}>
                            <InputLabel id="drone-select-reset">Drone Name</InputLabel>
                            <Select
                                labelId="drone-select-reset"
                                value={selectedDrone}
                                onChange={handleChange}
                                label="Drone Name">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {nameList.map(item => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
}

                <br/>
                <br/>
            </div>
            <div>
                <Button
                    onClick={handleFactoryReset}
                    variant="contained"
                    color="primary"
                    disabled={!selectedDrone}>Factory Reset Drone</Button>

            </div>
        </div>
    )
}
