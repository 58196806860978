import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {loginStyle, innerLoginStyle, imgStyle, buttonStyle, googleLogo} from "./loginStyles";

export default function LoginWindow(props) {
    return (
        <div>

            <Paper elevation={3} style={loginStyle}>
                <Grid container style={innerLoginStyle}>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <img style={imgStyle} alt="Teleport Logo" src="./logo-HighRes.png"/>
                    </Grid>
                    <Grid item xs={4}/>

                    <Grid item xs={12}>
                        <h1
                            style={{
                            textAlign: "center",
                            fontFamily: "Nunito"
                        }}>Teleport Speedtest</h1>
                    </Grid>

                    <Grid item xs={12}>

                        <Button style={buttonStyle} variant="outlined" onClick={() => props.signin()}>
                            <Grid container>
                                <Grid item xs={2}><img
                                    style={googleLogo}
                                    alt="Google Logo"
                                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/></Grid>
                                <Grid item xs>SIGN IN</Grid>
                            </Grid>

                        </Button>

                    </Grid>

                </Grid>
            </Paper>

        </div>
    )
}
