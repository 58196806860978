import React from 'react'
import MaterialTable from 'material-table'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle, faExclamationTriangle, faCheckCircle} from '@fortawesome/free-solid-svg-icons'

import tableIcons from '../../tableIcons'
import DroneDetails from './droneDetails/DroneDetails'

export default function DronesTable(props) {
    const {data} = props
    return (
            <div style={{
                maxWidth: "100%"
            }}>
                <MaterialTable
                    icons={tableIcons}
                    columns={[
                    {
                        title: "Name",
                        field: "name"
                    }, {
                        title: "Location",
                        field: "location"
                    }, {
                        title: "Refresh Period (Minutes)",
                        field: "refreshPeriod",
                        type: "numeric"
                    },{
                        title: "Ping",
                        field: "ping",
                        type: "numeric"
                    },{
                        title: "Download (Mpbs)",
                        field: "download",
                        type: "numeric"
                    },{
                        title: "Upload (Mpbs)",
                        field: "upload",
                        type: "numeric"
                    }, {
                        title: "Status",
                        field: "status",
                        type: "numeric",
                        lookup: {
                            0: <FontAwesomeIcon icon={faCheckCircle} style={{color: "green"}}/>,
                            1: <FontAwesomeIcon icon={faExclamationTriangle} style={{color: "orange"}}/>,
                            2: <FontAwesomeIcon icon={faExclamationCircle} style={{color: "red"}}/>
                        },
                        customSort: (a, b) => b.status - a.status
                    }
                ]}
                    data={data}
                detailPanel={rowData => {
                    return (
                        <DroneDetails rowData={rowData}/>
                    )
                  }}

                  options={{
                    actionsColumnIndex: -1,
                    sorting: true,
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 100]
                  }}
                    title="Drones"/>
            </div>
    )
}