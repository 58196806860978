import React, {useState, useEffect } from 'react'
import DroneTable from './DroneTable'
import app from '../../base'

export default function DronesPage() {
const db = app.firestore()
const [droneList, setDroneList] = useState([])
useEffect(() => {
    
    const unsubscribe = db.collection("drones").onSnapshot((snapshot) => {
        let tempList = []
        snapshot.forEach(doc => {
            if(doc.id === "drone-passwords" || doc.id === "droneStatus"){
                return;
            }
            let entry = {
                name: doc.id,
                location: doc.data().location,
                refreshPeriod: doc.data().refreshTime,
                ping: "none",
                download: "none",
                upload: "none",
                status: 0
            }
            if(doc.data().latestTest){
                entry.ping = doc.data().latestTest.ping
                entry.download = doc.data().latestTest.download
                entry.upload = doc.data().latestTest.upload
                entry.status = doc.data().status
            }
            tempList.push(entry)
        })
        setDroneList(tempList)
    })
    return () => {
        unsubscribe()
    }
}, [db])
    return (
        <div>
            <DroneTable data={droneList}/>
        </div>
    )
}