import React from 'react'
import MaterialTable from 'material-table'

import tableIcons from '../../tableIcons'
import AttentionList from './AttentionList'

export default function HomeTable(props) {
    const {data} = props
    return (
            <div style={{
                maxWidth: "100%"
            }}>
                <MaterialTable
                    icons={tableIcons}
                    columns={[{
                        title: "Time",
                        field: "time",
                        type: "time"
                    }, {
                        title: "Date",
                        field: "date",
                        type: "date"
                    },{
                        title: "Total Drones",
                        field: "total",
                        type: "numeric"
                    }, {
                        title: "Nominal Drones",
                        field: "nominal",
                        type: "numeric"
                    }, {
                        title: "Alert Drones",
                        field: "alert",
                        type: "numeric"
                    }, {
                        title: "Offline Drones",
                        field: "offline",
                        type: "numeric"
                    }
                ]}
                    data={data}

                    detailPanel={rowData => {
                        return (
                            <AttentionList alertList={rowData.alertList} offlineList={rowData.offlineList}/>
                        )
                      }}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                  }}
                    title="Drones"/>
            </div>
    )
}
