import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import UsersTable from './UsersTable'
import app from '../../base'

export default function PendingUsers() {
    const [pendingList,
        setPendingList] = useState([])

    useEffect(() => {
        const unsubscribe = app
            .firestore()
            .collection("users")
            .where("permission", "==", "pending")
            .onSnapshot(function (snapshot) {
                let tempPendingList = []
                snapshot.forEach(function (doc) {
                    const data = doc.data()
                    const newEntry = [
                        {
                            uid: doc.id,
                            name: data.name,
                            email: data.email,
                            isEmailVerified: data.isEmailVerified,
                            photoURL: data.photoURL,
                        }
                    ]
                    tempPendingList = [
                        ...tempPendingList,
                        ...newEntry
                    ]
                });
                setPendingList(tempPendingList)
            });

        return () => {
            unsubscribe()
        }

    }, [setPendingList])

    return (
        <div>
            <Typography
                style={{
                textAlign: "center"
            }}
                variant="h4">Pending Users</Typography>
            {pendingList.length
                ? <UsersTable data={pendingList} type="pending"/>
                : <Typography
                    style={{
                    textAlign: "center"
                }}
                    variant="h6"><br/>No pending users</Typography>}
        </div>
    )
}