import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import ErrorBoundary from './ErrorBoundary'
import App from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({dsn: "https://787e50a4bbba49cd9ccfd7776e727596@o385307.ingest.sentry.io/5217812"});

ReactDOM.render(
    <React.StrictMode>
    <ErrorBoundary>
        <App/>
    </ErrorBoundary>

</React.StrictMode>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();