export const loginStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    msTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    width: "42vh"
}

export const innerLoginStyle = {
    marginTop: "3vh",
    marginBottom: "3vh"
}

export const imgStyle = {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
}

export const buttonStyle = {
    display: 'block',
    margin: "auto",
    marginTop: "2vh",
    marginBottom: "2vh"
}

export const googleLogo = {
    display: "block",
    marginRight: "2rem",
    height: "1.5rem"
}