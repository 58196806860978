import React, {useState} from 'react'
import TodayDetails from './today/TodayDetails'
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core'
import ArchiveDetails from './archive/ArchiveDetails'

export default function DroneDetails(props) {
    const {rowData} = props
    const [isTodayView,
        setIsTodayView] = useState(true)
    const [weekNumbers,
        setWeekNumbers] = useState([])
    const [selectedWeekNum,
        setSelectedWeekNum] = useState("")
    const [selectedDataType,
        setSelectedDataType] = useState('download')

    const handleWeekNum = (event) => {
        setSelectedWeekNum(event.target.value)
    }

    return (
        <div style={{
            margin: 50
        }}>
            <FormControl
                variant="outlined"
                style={{
                minWidth: 140
            }}>
                <InputLabel id="time-period">Time Period</InputLabel>
                <Select
                    labelId="time-period"
                    value={isTodayView}
                    onChange={event => setIsTodayView(event.target.value)}
                    label="Time Period">
                    <MenuItem value={true}>
                        Today
                    </MenuItem>
                    <MenuItem value={false}>
                        Archive
                    </MenuItem>
                </Select>
            </FormControl>
            {!isTodayView && <span>
                <FormControl
                    variant="outlined"
                    style={{
                    minWidth: 180,
                    marginLeft: 20
                }}>
                    <InputLabel id="week-num">Week Num</InputLabel>
                    <Select
                        labelId="week-num"
                        value={selectedWeekNum}
                        onChange={handleWeekNum}
                        label="Week Num">
                        {weekNumbers.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl
                    variant="outlined"
                    style={{
                    minWidth: 140,
                    marginLeft: 20
                }}>
                    <InputLabel id="data-type">Data Type</InputLabel>
                    <Select
                        labelId="data-type"
                        value={selectedDataType}
                        onChange={event => setSelectedDataType(event.target.value)}
                        label="Data Type">
                        <MenuItem value='download'>
                            Download
                        </MenuItem>
                        <MenuItem value='upload'>
                            Upload
                        </MenuItem>
                        <MenuItem value='ping'>
                            Ping
                        </MenuItem>
                    </Select>
                </FormControl>
            </span>}

            <br/> {isTodayView
                ? <TodayDetails rowData={rowData}/>
                : <div>

                    <ArchiveDetails
                        rowData={rowData}
                        setWeekNumbers={setWeekNumbers}
                        selectedWeekNum={selectedWeekNum}
                        setSelectedWeekNum={setSelectedWeekNum}
                        selectedDataType={selectedDataType}/>
                </div>}

        </div>
    )
}
