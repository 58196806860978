import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MiniWidgets from './MiniWidgets';
import HomeGraph from './HomeChart';
import HomeTable from './HomeTable';
import app from '../../base';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }
}));

export default function HomePage() {
    const classes = useStyles();
    const [recordsList,
        setRecordsList] = useState([])
    const [labels,
        setLabels] = useState([])
    const [nominal,
        setNominal] = useState([])
    const [alert,
        setAlert] = useState([])
    const [offline,
        setOffline] = useState([])
    const [total,
        setTotal] = useState([])

    useEffect(() => {
        const db = app.firestore()
        const results = db
            .collection("droneStatus")
            .orderBy("timestamp", 'desc')

        results
            .limit(1)
            .onSnapshot(snapshot => {
                snapshot.forEach(doc => {
                    setRecordsList([])
                    let tempList = []
                    const data = doc.data()
                    for (const key in data) {
                        const element = data[key]
                        if (typeof(element) === "object") {
                            const elementDate = new Date(element.timestamp)
                            let nominalNum = 0
                            let alertNum = 0
                            let offlineNum = 0
                            let totalNum = 0
                            let alertList = []
                            let offlineList = []
                            const dronesObject = element.drones
                            for (const key in dronesObject) {
                                totalNum++;
                                switch (dronesObject[key]) {
                                    case 2:
                                        offlineNum++;
                                        offlineList.push(key);
                                        break;
                                    case 1:
                                        alertNum++;
                                        alertList.push(key);
                                        break;
                                    case 0:
                                        nominalNum++;
                                        break;

                                    default:
                                        break;
                                }
                            }
                            const entry = [
                                {
                                    timestamp: elementDate,
                                    date: elementDate.toDateString(),
                                    time: (elementDate.toTimeString()).slice(0, 8),
                                    total: totalNum,
                                    nominal: nominalNum,
                                    alert: alertNum,
                                    offline: offlineNum,
                                    alertList: alertList,
                                    offlineList: offlineList
                                }
                            ]
                            tempList = [
                                ...tempList,
                                ...entry
                            ]
                        }
                    }
                    tempList.sort((a, b) => (b.timestamp - a.timestamp))
                    setRecordsList(tempList)
                })

            })
    }, [setRecordsList])

    useEffect(() => {
        if (recordsList.length) {
            setNominal([])
            setAlert([])
            setOffline([])
            setLabels([])
            setTotal([])
            for (const i in recordsList) {
                setNominal(prev => [
                    ...prev,
                    recordsList[i].nominal
                ])
                setAlert(prev => [
                    ...prev,
                    recordsList[i].alert
                ])
                setOffline(prev => [
                    ...prev,
                    recordsList[i].offline
                ])
                setLabels(prev => [
                    ...prev,
                    recordsList[i].time
                ])
                setTotal(prev => [
                    ...prev,
                    recordsList[i].total
                ])
            }
        }

    }, [recordsList])
    return (
        <div className={classes.root}>
            <MiniWidgets/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <HomeGraph
                            total={total}
                            nominal={nominal}
                            alert={alert}
                            offline={offline}
                            labels={labels}/>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <HomeTable data={recordsList}/>
                </Grid>
            </Grid>
        </div>
    );
}