import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: null
        };
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    {Sentry.showReportDialog({eventId: this.state.eventId})}
                </div>
            )
        }
        return this.props.children;
    }
}