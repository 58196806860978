import React from 'react'
import {TextField, InputAdornment, Grid} from '@material-ui/core'

export default function ModificationForm(props) {
    const {setAreChanges ,newData, setNewData} = props

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        const locationRegex = /^$|^[A-Za-z0-9!"#%&'()*+,\-./:;<=>?@_\\ ]+$/
        switch (name) {
            case "downloadLimit":
                if (isNaN(value)) {
                    return
                }
                break;
            case "uploadLimit":
                if (isNaN(value)) {
                    return
                }
                break;
            case "pingLimit":
                if (isNaN(value)) {
                    return
                }
                break;
            case "refreshTime":
                if (isNaN(value)) {
                    return
                }
                break;
            case "location":
                if (!locationRegex.test(value)) {
                    return
                }
                break;

            default:
                break;
        }
        newData[name] = value
        const entry = {
            [name]: value
        }
        setAreChanges(true)
        setNewData(prev => ({
            ...prev,
            ...entry
        }))
    }
    return (
        <form noValidate autoComplete="off" style={{marginRight: 30, marginLeft: 30}}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        required
                        label="Ping Limit"
                        name="pingLimit"
                        InputProps={{
                        endAdornment: <InputAdornment position="end">ms</InputAdornment>
                    }}
                        variant="outlined"
                        value={newData.pingLimit || ""}
                        onChange={handleChange}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        label="Download Limit"
                        name="downloadLimit"
                        InputProps={{
                        endAdornment: <InputAdornment position="end">Mbps</InputAdornment>
                    }}
                        variant="outlined"
                        value={newData.downloadLimit || ""}
                        onChange={handleChange}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        label="Upload Limit"
                        name="uploadLimit"
                        InputProps={{
                        endAdornment: <InputAdornment position="end">Mbps</InputAdornment>
                    }}
                        variant="outlined"
                        value={newData.uploadLimit || ""}
                        onChange={handleChange}/>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={4}>
                    <TextField
                        required
                        label="Refresh Time"
                        name="refreshTime"
                        InputProps={{
                        endAdornment: <InputAdornment position="end">Minutes</InputAdornment>
                    }}
                        variant="outlined"
                        value={newData.refreshTime || ""}
                        onChange={handleChange}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        label="Location"
                        name="location"
                        variant="outlined"
                        value={newData.location|| ""}
                        onChange={handleChange}/>
                </Grid>
                <Grid item xs={2}/>
            </Grid>
        </form>
    )
}
