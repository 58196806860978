import React, {useEffect} from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

export default function DroneForm(props) {
    const {droneDetails, setDroneDetails, setIsAllowedContinue} = props

    useEffect(() => {
        setIsAllowedContinue(false)
    }, [setIsAllowedContinue])

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        const locationRegex = /^$|^[A-Za-z0-9!"#%&'()*+,\-./:;<=>?@_\\ ]+$/
        const nameRegex = /^$|^[A-Za-z0-9\-_]+$/
        switch (name) {
            case "name":
                if (!nameRegex.test(value)) {
                    return
                }
                break;
            case "location":
                if (!locationRegex.test(value)) {
                    return
                }
                break;
            case "refreshTime":
                if (isNaN(value)) {
                    return
                }
                break;
            case "pingLimit":
                if (isNaN(value)) {
                    return
                }
                break;

            case "downloadLimit":
                if (isNaN(value)) {
                    return
                }
                break;

            case "uploadLimit":
                if (isNaN(value)) {
                    return
                }
                break;

            default:
                break;
        }

        setDroneDetails(prev => ({
            ...prev,
            [name]: value
        }))

    }

    useEffect(() => {
        const {
            name,
            location,
            refreshTime,
            pingLimit,
            downloadLimit,
            uploadLimit
        } = droneDetails
        setIsAllowedContinue(name && location && refreshTime && pingLimit && downloadLimit && uploadLimit)

    }, [droneDetails, setIsAllowedContinue])

    return (
        <div style={{
            textAlign: "center"
        }}>
            <h2>{droneDetails.mac}</h2>
            <br/>
            <form noValidate autoComplete="off">
                <TextField
                    required
                    style={{
                    margin: 10
                }}
                    id="filled-nam1e"
                    name="name"
                    label="Name"
                    variant="outlined"
                    value={droneDetails.name || ""}
                    onChange={handleChange}/>

                <TextField
                    required
                    style={{
                    margin: 10
                }}
                    name="location"
                    label="Location"
                    variant="outlined"
                    value={droneDetails.location || ""}
                    onChange={handleChange}/>
                <br/>

                <TextField
                    style={{
                    margin: 10
                }}
                    required
                    label="Refresh Period"
                    name="refreshTime"
                    InputProps={{
                    endAdornment: <InputAdornment position="end">Minutes</InputAdornment>
                }}
                    variant="outlined"
                    value={droneDetails.refreshTime || ""}
                    onChange={handleChange}/>

                <TextField
                    style={{
                    margin: 10
                }}
                    required
                    label="Ping Limit"
                    name="pingLimit"
                    InputProps={{
                    endAdornment: <InputAdornment position="end">Ms</InputAdornment>
                }}
                    variant="outlined"
                    value={droneDetails.pingLimit || ""}
                    onChange={handleChange}/>

                <TextField
                    style={{
                    margin: 10
                }}
                    required
                    label="Download Limit"
                    name="downloadLimit"
                    InputProps={{
                    endAdornment: <InputAdornment position="end">Mbps</InputAdornment>
                }}
                    variant="outlined"
                    value={droneDetails.downloadLimit || ""}
                    onChange={handleChange}/>

                <TextField
                    style={{
                    margin: 10
                }}
                    required
                    label="Upload Limit"
                    name="uploadLimit"
                    InputProps={{
                    endAdornment: <InputAdornment position="end">Mbps</InputAdornment>
                }}
                    variant="outlined"
                    value={droneDetails.uploadLimit || ""}
                    onChange={handleChange}/>
            </form>
            <br/>
        </div>
    )
}
