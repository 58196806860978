import React from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import {makeAdmin, makeMaintainer, deleteUser} from './userControl'

export default function UsersTable(props) {
    const {type} = props
    
    const ActionButtons = (props) => {
        const {uid} = props
        if (type === "pending") {
            return (
                <ButtonGroup
                    fullWidth
                    color="primary"
                    aria-label="outlined primary button group">
                    <Button onClick={() => makeMaintainer(uid)}>Make Maintainer</Button>
                    <Button onClick={() => makeAdmin(uid)}>Make Admin</Button>
                    <Button color="secondary" onClick={() => deleteUser(uid)}>Delete</Button>
                </ButtonGroup>
            )
        } else if (type === "maintainer") {
            return (
                <ButtonGroup
                    fullWidth
                    color="primary"
                    aria-label="outlined primary button group">
                    <Button onClick={() => makeAdmin(uid)}>Make Admin</Button>
                    <Button color="secondary" onClick={() => deleteUser(uid)}>Delete</Button>
                </ButtonGroup>
            )
        } else {
            return (
                <ButtonGroup
                    fullWidth
                    color="primary"
                    aria-label="outlined primary button group">
                    <Button onClick={() => makeMaintainer(uid)}>Make Maintainer</Button>
                    <Button color="secondary" onClick={() => deleteUser(uid)}>Delete</Button>
                </ButtonGroup>
            )
        }

    }

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Icon</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Is email verified?</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props
                            .data
                            .map((row) => (

                                <TableRow key={row.uid}>
                                    <TableCell component="th" scope="row">
                                        <Avatar alt="User Icon" src={row.photoURL}/>
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    <TableCell align="left">{row.isEmailVerified
                                            ? "yes"
                                            : "no"}</TableCell>
                                    <TableCell align="right"><ActionButtons uid={row.uid}/></TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
