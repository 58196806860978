import React, {useEffect, useState} from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import ErrorBox from '../../ErrorBox'

import isDroneOnline from '../../../isDroneOnline'

import app from '../../../base'
import {CircularProgress} from '@material-ui/core'

export default function SelectDrone(props) {
    const {setIsAllowedContinue, droneDetails, setDroneDetails} = props

    const [macList,
        setMacList] = useState([])

    const [isLoading,
        setIsLoading] = useState(false)

    const [error,
        setError] = useState([false, "", ""])

    useEffect(() => {
        const unsubscribe = app
            .firestore()
            .collection("pending")
            .onSnapshot(function (snapshot) {
                snapshot
                    .docChanges()
                    .forEach(function (change) {
                        if (change.type === "added") {
                            if (change.doc.id !== "pending-drone-tokens" && !macList.includes(change.doc.id)) {
                                setMacList(prev => prev.concat(change.doc.id))
                            }
                        }
                    });
            });

        return () => {
            unsubscribe()
        }

    }, [macList, setMacList])

    useEffect(() => {
        const unsubscribe = app
            .firestore()
            .collection("pending")
            .onSnapshot(function (snapshot) {
                snapshot
                    .docChanges()
                    .forEach(function (change) {
                        if (change.type === "removed") {
                            setMacList(prev => prev.filter(item => item !== change.doc.id))
                            if (droneDetails.mac === change.doc.id) {
                                setDroneDetails(prev => ({
                                    ...prev,
                                    mac: ""
                                }))
                            }

                        }
                    });
            });

        return () => {
            unsubscribe()
        }

    }, [droneDetails.mac, setDroneDetails, setMacList])

    const handleChange = (event) => {
        const value = event.target.value
        setDroneDetails(prev => ({
            ...prev,
            mac: value
        }))
        if (value) {
            setIsLoading(true)
            isDroneOnline(value, "pending", status => {
                setIsLoading(false)
                if (status) {
                    setIsAllowedContinue(true)
                } else {
                    setIsAllowedContinue(false)
                    setError([true, "Drone Offline", "The drone is currently offline and thus cannot be set up."])
                    setDroneDetails(prev => ({
                        ...prev,
                        mac: ""
                    }))
                }
            })
        } else {
            setIsAllowedContinue(false)
        }

    }

    return (
        <div style={{
            textAlign: "center"
        }}>
            {error[0] && <ErrorBox title={error[1]} text={error[2]} setError={setError}/>}
            Select the MAC Address of the drone you want to configure.<br/>Please allow up to 5 minutes for the drone to show up.<br/><br/> {isLoading
                ? <CircularProgress size={50}/>
                : <FormControl
                    variant="outlined"
                    style={{
                    minWidth: 140
                }}>
                    <InputLabel id="drone-select">MAC Address</InputLabel>
                    <Select
                        labelId="drone-select"
                        value={droneDetails.mac}
                        onChange={handleChange}
                        label="MAC Address">
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {macList.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}

                    </Select><br/>
                </FormControl>
}

        </div>
    )
}
