import React, {useState, useEffect} from 'react'
import {
    Button,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    FormControl
} from '@material-ui/core'
import app from '../../../base'
import ModificationForm from './ModificationForm'

export default function DroneModify() {
    const [selectedDrone,
        setSelectedDrone] = useState("")
    const [nameList,
        setNameList] = useState([])
    const [newData,
        setNewData] = useState({})
    const [areChanges, setAreChanges] = useState(false)

    useEffect(() => {
        async function runEffect() {
            if (!selectedDrone) {
                return
            }
            const db = app.firestore()
            const drone = await db
                .collection("drones")
                .doc(selectedDrone)
                .get()
            const droneData = drone.data()
            const prohibited = ["factoryReset", "status", "name", "latestTest", "onlineCheck"]
            for (const key in droneData) {
                if (!prohibited.includes(key)) {
                    const entry = {
                        [key]: droneData[key]
                    }
                    setNewData(prev => ({
                        ...prev,
                        ...entry
                    }))
                }
            }
        }
        runEffect()
    }, [selectedDrone, setNewData])

    useEffect(() => {
        const unsubscribe = app
            .firestore()
            .collection("drones")
            .onSnapshot(function (snapshot) {
                snapshot
                    .docChanges()
                    .forEach(function (change) {
                        if (change.type === "added") {
                            if (change.doc.id !== "drone-passwords" && !nameList.includes(change.doc.id)) {
                                setNameList(prev => prev.concat(change.doc.id))
                            }
                        }
                        if (change.type === "removed") {
                            if (change.doc.id === selectedDrone) {
                                setSelectedDrone("")
                            }
                            setNameList(prev => prev.filter(item => item !== change.doc.id))

                        }
                    });
            });

        return () => {
            unsubscribe()
        }

    }, [nameList, setNameList, selectedDrone])

    const handleDroneSelect = (event) => {
        setSelectedDrone(event.target.value)

    }

    const handleModification = async() => {
        await app.firestore().collection("drones").doc(selectedDrone).set(newData, {merge: true})
        setAreChanges(false)
        setSelectedDrone("")
        setNewData({})
    }

    const handleCancelChanges = () => {
        setAreChanges(false)
        setSelectedDrone("")
        setNewData({})
    }
    return (
        <div>
            <Typography
                style={{
                textAlign: "center"
            }}
                variant="h4">Modify Drone Details</Typography>

            <div style={{
                textAlign: "center"
            }}>
                Select the name of the drone you want to modify<br/><br/>
                <FormControl
                    variant="outlined"
                    style={{
                    minWidth: 140
                }}>
                    <InputLabel id="drone-select-reset">Drone Name</InputLabel>
                    <Select
                        labelId="drone-select-reset"
                        value={selectedDrone}
                        onChange={handleDroneSelect}
                        label="Drone Name">
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {nameList.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}

                    </Select>
                </FormControl>

                <br/>
                <br/>
                {selectedDrone && <ModificationForm newData={newData} setNewData={setNewData} setAreChanges={setAreChanges}/>}
                <br/>
            </div>
            <div>
                <Button
                    style={{
                    marginRight: 15
                }}
                    onClick={handleModification}
                    variant="contained"
                    color="primary"
                    disabled={!areChanges}>Save Modifications</Button>

                <Button
                    onClick={handleCancelChanges}
                    variant="contained"
                    color="primary"
                    disabled={!areChanges}>Cancel Changes</Button>
            </div>
        </div>
    )
}
