import React, {useEffect, useState} from 'react'
import Typography from '@material-ui/core/Typography'
import app from '../../base'
import UsersTable from './UsersTable'

export default function Maintainers() {
    const [maintainerList,
        setMaintainerList] = useState([])

    useEffect(() => {
        const unsubscribe = app
            .firestore()
            .collection("users")
            .where("permission", "==", "maintainer")
            .onSnapshot(function (snapshot) {
                let tempMaintainerList = []
                snapshot.forEach(function (doc) {
                    const data = doc.data()
                    const newEntry = [
                        {
                            uid: doc.id,
                            name: data.name,
                            email: data.email,
                            isEmailVerified: data.isEmailVerified,
                            photoURL: data.photoURL,
                        }
                    ]
                    tempMaintainerList = [
                        ...tempMaintainerList,
                        ...newEntry
                    ]
                });
                setMaintainerList(tempMaintainerList)
            });

        return () => {
            unsubscribe()
        }

    }, [setMaintainerList])
    return (

        <div>

            <Typography
                style={{
                textAlign: "center"
            }}
                variant="h4">Maintainers</Typography>
            {maintainerList.length
                ? <UsersTable data={maintainerList} type="maintainer"/>
                : <Typography
                    style={{
                    textAlign: "center"
                }}
                    variant="h6"><br/>No maintainers</Typography>}
        </div>
    )
}
