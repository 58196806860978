import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog, faUserCog, faSignOutAlt, faTable, faHome} from '@fortawesome/free-solid-svg-icons'

import {Link, Redirect} from "react-router-dom";
import app from "./base";
import KillDrones from "./KillDrones";

const drawerWidth = 240;

export default function MaterialDrawer(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex"
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme
                .transitions
                .create([
                    'width', 'margin'
                ], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                })
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme
                .transitions
                .create([
                    'width', 'margin'
                ], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                })
        },
        menuButton: {
            marginRight: 36
        },
        hide: {
            display: 'none'
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap'
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme
                .transitions
                .create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                })
        },
        drawerClose: {
            transition: theme
                .transitions
                .create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [
                theme
                    .breakpoints
                    .up('sm')
            ]: {
                width: theme.spacing(9) + 1
            }
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3)
        }
    }));

    const {
        links,
        children,
        auth,
        setAuth,
        permission,
        setPermission
    } = props
    const classes = useStyles();
    const theme = useTheme();
    const [open,
        setOpen] = useState(false);

    useEffect(() => {
        if (auth) {
            const unsubscribe = app
                .firestore()
                .collection('users')
                .doc(auth.uid)
                .onSnapshot(doc => {
                    if (doc.exists) {
                        setPermission(doc.data().permission)
                    } else {
                        app
                            .auth()
                            .signOut()
                            .then(function () {
                                setAuth(false)
                            })
                            .catch(function (error) {
                                console.log(error)
                            });
                    }

                })
            return () => {
                unsubscribe()
            }
        }
    }, [setPermission, auth, setAuth])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (

        <div className={classes.root}>
            {permission === "pending" && <Redirect to='/pending'/>}
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                [classes.appBarShift]: open
            })}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                        [classes.hide]: open
                    })}>
                        <MenuIcon/>
                    </IconButton>
                    <img
                        src="../logo64.png"
                        style={{
                        height: 40,
                        marginRight: 10
                    }}
                        alt="Teleport Logo"/>
                    <Typography variant="h5" noWrap>
                        <b>Teleport Speedtest</b>
                    </Typography>
                    <div style={{
                        marginLeft: 'auto'
                    }}>
                        <KillDrones permission={permission}/>
                    </div>

                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
            })}
                classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })
            }}>
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl'
                            ? <ChevronRightIcon/>
                            : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                <List>

                    <ListItem button component={Link} to={links.home}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faHome} size="2x"/>
                        </ListItemIcon>
                        <ListItemText primary="Home"/>
                    </ListItem>

                    <ListItem button component={Link} to={links.drones}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faTable} size="2x"/>
                        </ListItemIcon>
                        <ListItemText primary="Drones"/>
                    </ListItem>

                    <ListItem button component={Link} to={links.droneSettings}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faCog} size="2x"/>
                        </ListItemIcon>
                        <ListItemText primary="Drone Settings"/>
                    </ListItem>

                </List><Divider/> {permission === "admin" && <div>
                    <List>

                        <ListItem button component={Link} to={links.userSettings}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faUserCog} size="2x"/>
                            </ListItemIcon>
                            <ListItemText primary="User Settings"/>
                        </ListItem>
                    </List>
                </div>}
                <List>
                    <ListItem button component={Link} to={links.logout}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSignOutAlt} rotation={180} size="2x"/>
                        </ListItemIcon>
                        <ListItemText primary="Logout"/>
                    </ListItem>

                </List>

            </Drawer>
            < main className={classes.content}>
                <div className={classes.toolbar}/> {children}
            </main>
        </div >
    );
}