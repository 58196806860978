import app from './base'
const db = app.firestore()

export default function isDroneOnline(uid, mode, callback) {
    let dbEntry
    if (mode === "pending" || mode === "operational") {
        if (mode === "pending") {
            dbEntry = db.collection("pending").doc(uid)
        } else {
            dbEntry = db.collection("drones").doc(uid)
        }
    } else {
        console.error("Error: incorrect mode given")
        callback(false)
    }
    dbEntry.set({
        onlineCheck: true
    }, {
        merge: true
    }).then(() => {
        setTimeout(() => {
            dbEntry.get().then(doc => {
                if (doc.exists) {
                    if (doc.data().onlineCheck) { // if it hasnt been changed by the drone
                        dbEntry.set({
                            onlineCheck: false
                        }, {
                            merge: true
                        }).then(() => {
                            callback(false)
                        }).catch(error => {
                            console.error("Error cleaning up failed online check", error)
                            callback(false)
                        })
                    } else {
                        callback(true)
                    }
                } else {
                    console.error("Document doesen't exist")
                    callback(false)
                }
            }).catch(error => {
                console.log("Error getting onlineCheck value", error)
                callback(false)
            })
        }, 1000);
    }).catch(error => {
        console.error("Error making online check", error)
        callback(false)
    })
}