import React, {useEffect} from 'react'
import {Redirect} from "react-router-dom";
import app from '../base'

export default function LogoutPage(props) {
    const {auth, setAuth} = props
    useEffect(() => {
        app
            .auth()
            .signOut()
            .then(function () {
                // Sign-out successful.
                setAuth(false)
            })
            .catch(function (error) {
                // An error happened.
                console.log(error)
            });
    }, [setAuth])

    return (
        <div>
            {auth
                ? <p>Logging out...</p>
                : <Redirect to="/login"/>}
        </div>
    )
}
