import React, {useEffect, useState} from 'react'
import app from '../../base'
import firebase from 'firebase'
import LoginWindow from './LoginWindow'
import {Redirect} from 'react-router-dom';
import LoginPageLoading from './LoginPageLoading';

export default function LoginPage(props) {
    const {auth, setAuth, permission, setPermission} = props
    const [loading,
        setLoading] = useState(true)

    const provider = new firebase
        .auth
        .GoogleAuthProvider()

    useEffect(() => {
        const unsubscribe = firebase
            .auth()
            .onAuthStateChanged(function (user) {
                if (user) {
                    const userDB = app
                    .firestore()
                    .collection("users")
                    .doc(user.uid)
                
                const getPermission = (user) => {
        
                    userDB
                        .get()
                        .then(doc => {
                            setPermission(doc.data().permission)
                            setAuth(user)
                        })
                        .catch(error => {
                            getPermission(user)
                            console.log("Trying Again")
                        })
                }
                console.log("Trying to get user permission")
                getPermission(user)

                } else {
                    setAuth(false);
                    setLoading(false)
                }
            });
        return () => {
            unsubscribe()
        }
    }, [setAuth, setLoading, setPermission])

    app
        .auth()
        .getRedirectResult()
        .catch(function (error) {
            // Handle Errors here. var errorCode = error.code; var errorMessage =
            // error.message; The email of the user's account used. var email = error.email;
            // The firebase.auth.AuthCredential type that was used. var credential =
            // error.credential; ...
        });

    function signin() {
        app
            .auth()
            .signInWithRedirect(provider)
    }

    return (
        <div>
            {auth
                ? (permission === "pending"
                    ? <Redirect to='/pending'/>
                    : <Redirect to='/home'/>)
                : (loading
                    ? <LoginPageLoading/>
                    : <LoginWindow signin={signin}/>)}

        </div>
    )
}
