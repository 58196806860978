import React, {useEffect, useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import app from '../../../base'
import isDroneOnline from '../../../isDroneOnline'
import ErrorBox from '../../ErrorBox'
export default function DroneDeploy(props) {
    const [spinnerStyle,
        setSpinnerStyle] = useState({display: "block", margin: "auto", textAlign: "center"})
    const {setIsAllowedContinue, droneDetails} = props
    const [error,
        setError] = useState([false, "", ""])
    const [isDroneDeployed,
        setIsDroneDeployed] = useState(false)

    useEffect(() => {
        app
            .firestore()
            .collection("drones")
            .get()
            .then(snapshot => {
                let continueToDeploy = true
                snapshot.forEach(doc => {
                    console.log("drones in database: ", doc.id)
                    console.log("selectedDroneName", droneDetails.name)
                    if (doc.id === droneDetails.name) {
                        setSpinnerStyle({display: "none"})
                        setIsAllowedContinue(true)
                        setError([true, "Drone Already Exists", "A drone with that name already exists"])
                        continueToDeploy = false
                        return
                    }
                });
                if (continueToDeploy) {
                    console.log("SHOULD ONLY RUN ONCE")
                    isDroneOnline(droneDetails.mac, "pending", status => {
                        if (status) {
                            const {mac, name, location, refreshTime, pingLimit, downloadLimit, uploadLimit} = droneDetails
                            const randPassword = Math
                                .random()
                                .toString(36)
                                .substring(2, 15) + Math
                                .random()
                                .toString(36)
                                .substring(2, 15);
                            if (mac) {
                                const db = app.firestore()
                                db
                                    .collection("drones")
                                    .doc("drone-passwords")
                                    .set({
                                        [name]: randPassword
                                    }, {merge: true})
                                    .then(() => {
                                        db
                                            .collection("pending")
                                            .doc(mac)
                                            .set({
                                                name: name,
                                                location: location,
                                                refreshTime: refreshTime,
                                                password: randPassword,
                                                pingLimit: pingLimit,
                                                downloadLimit: downloadLimit,
                                                uploadLimit: uploadLimit,
                                                factoryReset: false,
                                                onlineCheck: false
                                            }, {merge: true})
                                            .then(function () {
                                                console.log("Document successfully written!");
                                            })
                                            .catch(function (error) {
                                                console.error("Error writing document: ", error);
                                                setSpinnerStyle({display: "none"})
                                                setIsAllowedContinue(true)
                                                setError([true, "Unknown Error", "An unknown error occured"])
                                            });
                                    })
                                    .catch(error => {
                                        console.error("Error writing document: ", error);
                                        setSpinnerStyle({display: "none"})
                                        setIsAllowedContinue(true)
                                        setError([true, "Unknown Error", "An unknown error occured"])
                                    });

                            }
                        } else {
                            setSpinnerStyle({display: "none"})
                            setIsAllowedContinue(true)
                            setError([true, "Drone Offline", "The drone is offline and thus cannot be deployed"])

                        }
                    })
                }

            })

    }, [droneDetails, setIsAllowedContinue])

    useEffect(() => {
        const deleteUser = app
        .functions()
        .httpsCallable('deleteUser')
        
        const db = app.firestore()
        let unsubscribe = () => {}
        if (droneDetails.mac) {
            unsubscribe = db
                .collection("pending")
                .doc(droneDetails.mac)
                .onSnapshot(function (doc) {
                    if (!doc.data()) { //if its been deleted
                        console.log("about to delete mac user")
                        deleteUser({uid: droneDetails.mac}).then(() => {
                            isDroneOnline(droneDetails.name, "operational", (status) => {
                                setSpinnerStyle({display: "none"})
                                setIsAllowedContinue(true)
                                if (status) {
                                    setIsDroneDeployed(true)
                                } else {
                                    setError([true, "Unknown Error", "An unknown error occured"])
                                }
                            })

                        })

                    }
                })
        }

        return () => {
                unsubscribe();
        }
    }, [setIsAllowedContinue, droneDetails.mac, droneDetails.name])

    return (
        <div style={{
            textAlign: "center"
        }}>
            {error[0] && <ErrorBox title={error[1]} text={error[2]} setError={setError}/>}
            <div style={spinnerStyle}>
                <br/>
                <CircularProgress size={100}/><br/><br/>
                <h2>Deploying...</h2>
            </div>
            {isDroneDeployed && <h2>Drone successfully deployed</h2>}
        </div>

    )
}
