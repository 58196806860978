import React from 'react'
import {Grid, Typography} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'

export default function AttentionList(props) {
    const {alertList, offlineList} = props
    return (
        <div style={{
            margin: 50
        }}>
            {(alertList[0] || offlineList[0])
                ? <div>
                        {alertList[0] && <div>
                            <Typography
                                variant='h5'
                                style={{
                                textAlign: 'left'
                            }}>
                                Alert Drones &nbsp;
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    style={{
                                    color: "orange"
                                }}/>
                                <hr/>
                            </Typography>
                            <Grid container>
                                {alertList.map(drone => <Grid item xs={4} key={drone}>
                                    <Typography variant='h6'>
                                        {drone}
                                    </Typography>
                                </Grid>)}
                            </Grid>
                        </div>}

                        {offlineList[0] && <div>
                            <br/>
                            <Typography
                                variant='h5'
                                style={{
                                textAlign: 'left'
                            }}>
                                Offline Drones &nbsp;
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    style={{
                                    color: "red"
                                }}/>
                                <hr/>
                            </Typography>
                            <Grid container>
                                {offlineList.map(drone => <Grid item xs={4} key={drone}>
                                    <Typography variant='h6'>
                                        {drone}
                                    </Typography>
                                </Grid>)}
                            </Grid>
                        </div>}
                    </div>
                : <Typography variant='h5' style={{textAlign: 'center'}}>No drones require attention</Typography>}
        </div>
    )
}
