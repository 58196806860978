import React, {useState, useEffect} from 'react'
import DronesDetailsTodayTable from './DroneDetailsTodayTable'
import DroneDetailsTodayChart from './DroneDetailsTodayChart'
import app from '../../../../base'
export default function TodayDetails(props) {
    const {rowData} = props
    const [recordsList,
        setRecordsList] = useState([])
    const [ping,
        setPing] = useState([])
    const [download,
        setDownload] = useState([])
    const [upload,
        setUpload] = useState([])
    const [labels,
        setLabels] = useState([])

    useEffect(() => {
        const db = app.firestore()
        const results = db
            .collection("drones")
            .doc(rowData.name)
            .collection("data")
            .orderBy("timestamp", 'desc')

        results
            .limit(1)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let tempList = []
                    const data = doc.data()
                    for (const key in data) {
                        const element = data[key]
                        if (typeof(element) === "object") {
                            const elementDate = new Date(element.timestamp)
                            const entry = [
                                {
                                    timestamp: elementDate,
                                    date: elementDate.toDateString(),
                                    time: (elementDate.toTimeString()).slice(0, 8),
                                    ping: element.ping,
                                    download: element.download,
                                    upload: element.upload
                                }
                            ]
                            tempList = [
                                ...tempList,
                                ...entry
                            ]
                        }
                    }
                    tempList.sort((a, b) => (b.timestamp - a.timestamp))
                    setRecordsList(tempList)
                })

            })
    }, [setRecordsList, rowData.name])

    useEffect(() => {
        for (const i in recordsList) {
            setPing(prev => [
                ...prev,
                recordsList[i].ping
            ])
            setDownload(prev => [
                ...prev,
                recordsList[i].download
            ])
            setUpload(prev => [
                ...prev,
                recordsList[i].upload
            ])
            setLabels(prev => [
                ...prev,
                recordsList[i].time
            ])
        }
    }, [recordsList])
    return (
        <div>
            {!recordsList.length
                ? <h1 style={{
                        textAlign: 'center'
                    }}>Loading...</h1>
                : <div>
                    <DroneDetailsTodayChart
                        ping={ping}
                        download={download}
                        upload={upload}
                        labels={labels}/>
                    <br/>
                    <DronesDetailsTodayTable data={recordsList}/>
                </div>}
        </div>
    )
}
