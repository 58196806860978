import React, {useState} from 'react'

import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import DroneSetupSteps from './DroneSetupSteps';
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    backButton: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function DroneProvisioning() {
    const classes = useStyles();
    const [activeStep,
        setActiveStep] = useState(0);

    const [isAllowedContinue,
        setIsAllowedContinue] = useState(false)

    const [droneDetails,
        setDroneDetails] = useState({mac: "", name: "", location: "", refreshTime: "", pingLimit: "", downloadLimit: "", uploadLimit: ""})

    const steps = ['Select drone to provision', 'Enter additional settings', 'Deploy drone']

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setIsAllowedContinue(false)
        setDroneDetails({mac: "", name: "", location: "", refreshTime: "", pingLimit: "", downloadLimit: "", uploadLimit: ""})
    };

    return (
        <div>
            <Typography
                style={{
                textAlign: "center"
            }}
                variant="h4">Drone Provisioning</Typography>

            <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <DroneSetupSteps
                    step={activeStep}
                    isAllowedContinue={isAllowedContinue}
                    setIsAllowedContinue={setIsAllowedContinue}
                    droneDetails={droneDetails}
                    setDroneDetails={setDroneDetails}/>

                <div>
                    {activeStep === steps.length
                        ? (
                            <div>
                                <Button
                                    onClick={handleReset}
                                    variant="contained"
                                    color="primary"
                                    disabled={!isAllowedContinue}>Deploy Another Drone</Button>
                            </div>
                        )
                        : (
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.backButton}>
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={!isAllowedContinue}>
                                    {activeStep === steps.length - 1
                                        ? 'Deploy'
                                        : 'Next'}
                                </Button>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}
