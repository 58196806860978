import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Paper } from '@material-ui/core'
export default function LoginPageLoading() {
    const loadingStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
        width: "42vh",
        height: "30vh"
    }
    const progressStyle = {
        marginTop: "7vh",
        marginBottom: "7vh",
        marginLeft: "13vh",
        marginRight: "13vh"
    }
    return (

        <div>
            <Paper elevation={3} style={loadingStyle} >
<CircularProgress size="16vh" style={progressStyle}/>
            </Paper>
            
        </div>
    )
}
