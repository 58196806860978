import React, { useEffect } from 'react'

export default function DroneDetails(props) {
    const {setIsAllowedContinue, droneDetails} = props
    useEffect(() => {
        setIsAllowedContinue(true)
    }, [setIsAllowedContinue])

    return (
        <div style={{textAlign: "center"}}>
            Please double check if this is the correct configuration data:<br/>
            <h2>
                MAC Address: {droneDetails.mac}<br/>
                Name: {droneDetails.name}<br/>
                Location: {droneDetails.location}<br/>
                Referesh Interval: {droneDetails.refreshTime} Minutes<br/>
                Maximum Ping: {droneDetails.pingLimit} ms<br/>
                Minimum Download: {droneDetails.downloadLimit} Mbps<br/>
                Minimum Upload: {droneDetails.uploadLimit} Mbps<br/>
            </h2>
        </div>
    )
}
