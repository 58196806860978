import React, {useState, useEffect} from 'react'
import app from './base'
import {FormGroup, FormControlLabel, Switch, Typography} from '@material-ui/core'

export default function KillDrones(props) {
    const {permission} = props
    const [isStopped,
        setIsStopped] = useState(false)
    useEffect(() => {
        const unsubscribe = app
            .firestore()
            .collection("global")
            .doc("stopAllDrones")
            .onSnapshot(doc => {
                const value = doc
                    .data()
                    .value
                setIsStopped(value)
            })
        return () => {
            unsubscribe()
        }
    }, [setIsStopped])

    const handleChange = (event) => {
        app
            .firestore()
            .collection("global")
            .doc("stopAllDrones")
            .set({value: event.target.checked})
    }
    return (
        <div>
            {permission === 'admin'
                ? <div>
                        <FormGroup>
                            <FormControlLabel
                                control={< Switch checked = {
                                isStopped
                            }
                            onChange = {
                                handleChange
                            }
                            name = "stopped" />}
                                label="Stop All Drones"/>
                        </FormGroup>
                    </div>
                : isStopped && <Typography>All drones are currently stopped</Typography>}
        </div>

    )
}
