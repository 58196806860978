import React from 'react'
import DroneProvisioning from './DroneProvisioning/DroneProvisioning'
import Paper from '@material-ui/core/Paper'
import DroneFactoryReset from './DronefactoryReset/DroneFactoryReset'
import DroneModify from './DroneModify/DroneModify'

const paperStyle = {
    paddingTop: "10px",
    paddingBottom: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
}


export default function DroneSettingsPage() {

    return (
        <div>
            <Paper style={paperStyle} elevation={3}>
                <DroneProvisioning/>
            </Paper>
            <Paper style={{...paperStyle, marginTop: 30}} elevation={3}>
                <DroneModify/>
            </Paper>
            <Paper style={{...paperStyle, marginTop: 30}} elevation={3}>
                <DroneFactoryReset/>
            </Paper>
        </div>
    )
}
