import React, {useState, useEffect} from 'react'
import app from '../../../../base'
import ArchiveDetailsChart from './ArchiveDetailsChart'

export default function ArchiveDetails(props) {
    const {rowData, setWeekNumbers, selectedWeekNum, setSelectedWeekNum, selectedDataType} = props
    const [labels,
        setLabels] = useState([])
    const [dataOne,
        setDataOne] = useState([])
    const [dataTwo,
        setDataTwo] = useState([])
    const [rawData,
        setRawData] = useState()
    const [dataExists,
        setDataExists] = useState(true)
    useEffect(() => {
        const db = app.firestore()
        const now = new Date(Date.now())
        const year = now.getUTCFullYear()
        db
            .collection('archive')
            .doc(rowData.name + '-' + year)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setRawData(doc.data())
                } else {
                    setDataExists(false)
                }

            })
    }, [setRawData, setDataExists, rowData])

    useEffect(() => {
        setWeekNumbers([])
        for (const key in rawData) {
            if(key === "drone") {
                return
            }
            setWeekNumbers(prev => [
                ...prev,
                key
            ])
            setSelectedWeekNum(key)
        }
    }, [rawData, setSelectedWeekNum, setWeekNumbers])

    useEffect(() => {
        if (!rawData) {
            return
        }
        const rawWeekData = rawData[selectedWeekNum]
        let timestamps = []
        for (const key in rawWeekData) {
            timestamps = [
                ...timestamps,
                key
            ]

        }
        timestamps.sort((a, b) => a - b)
        setLabels([])
        setDataOne([])
        setDataTwo([])
        timestamps.forEach(time => {
            const date = new Date(parseInt(time))
            const day = date.getUTCDate() + '/' + date.getUTCMonth() + '/' + date.getUTCFullYear()

            setLabels(prev => [
                ...prev,
                day
            ])
            setDataOne(prev => [
                ...prev,
                rawWeekData[time][selectedDataType][1]
            ])
            setDataTwo(prev => [
                ...prev,
                rawWeekData[time][selectedDataType][0]
            ])
        })

    }, [selectedWeekNum, rawData, selectedDataType])
    return (
        <div>
            {dataOne.length
                ? <ArchiveDetailsChart
                        dataOne={dataOne}
                        dataTwo={dataTwo}
                        labels={labels}
                        selectedDataType={selectedDataType}/>
                : (dataExists
                    ? <h1 style={{textAlign: 'center'}}>Loading...</h1>
                    : <h1 style={{textAlign: 'center'}}>No Data: Drone is younger than a week</h1>)
}

        </div>
    )
}
