import React from 'react'
import {Line} from 'react-chartjs-2';

export default function ArchiveDetailsChart(props) {
    const {dataOne, dataTwo, selectedDataType, labels} = props
    let color
    switch (selectedDataType) {
        case 'download':
            color = 'green'
            break;
        case 'upload':
            color = 'red'
            break;
        case 'ping':
            color = 'orange'
            break;

        default:
            break;
    }
    const tableData = {
        labels: labels,
        datasets: [
            {
                label: 'Upper 5%',
                fill: false,
                lineTension: 0.1,
                borderColor: color,
                pointBorderColor: color,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataOne
            }, {
                label: 'Lower 5%',
                fill: false,
                lineTension: 0.1,
                borderColor: color,
                pointBorderColor: color,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: dataTwo
            }
        ]
    }
    return (
        <div style={{height: "60vh"}}>
            <Line data={tableData} options={{maintainAspectRatio: false}}/>
        </div>
    )
}
