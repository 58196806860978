import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'

import UsersTable from './UsersTable'

import app from '../../base'

export default function Admins(props) {
    const {auth} = props
    const [adminList,
        setAdminList] = useState([])


    useEffect(() => {
        const unsubscribe = app
            .firestore()
            .collection("users")
            .where("permission", "==", "admin")
            .onSnapshot(snapshot => {
                let tempAdminList = []
                snapshot.forEach(function (doc) {
                    const data = doc.data()
                    const newEntry = [
                        {
                            uid: doc.id,
                            name: data.name,
                            email: data.email,
                            isEmailVerified: data.isEmailVerified,
                            photoURL: data.photoURL,
                        }
                    ]
                    if (doc.id !== auth.uid) {
                        tempAdminList = [
                            ...tempAdminList,
                            ...newEntry
                        ]
                    }
                });
                setAdminList(tempAdminList)
            }, err => {
                console.log(err)
            });

        return () => {
            unsubscribe()
        }

    }, [setAdminList, auth.uid])

    return (
        <div>
            <Typography
                style={{
                textAlign: "center"
            }}
                variant="h4">Admins</Typography>
            {adminList.length
                ? <UsersTable data={adminList} type="admin"/>
                : <Typography
                    style={{
                    textAlign: "center"
                }}
                    variant="h6"><br/>No admins other than you</Typography>}
        </div>
    )
}