import React from 'react'
import {Line} from 'react-chartjs-2';

export default function HomeChart(props) {
    const {total, nominal, alert, offline, labels} = props
    const tableData = {
        labels: labels,
        datasets: [
            {
                label: 'Nominal',
                fill: false,
                lineTension: 0.4,
                borderColor: 'green',
                pointBorderColor: 'green',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: nominal
            }, {
                label: 'Offline',
                fill: false,
                lineTension: 0.4,
                borderColor: 'red',
                pointBorderColor: 'red',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: offline
            }, {
                label: 'Alert',
                fill: false,
                lineTension: 0.4,
                borderColor: 'orange',
                pointBorderColor: 'orange',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: alert
            }, {
                label: 'Total',
                fill: false,
                lineTension: 0.1,
                borderColor: 'blue',
                pointBorderColor: 'blue',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: total
            }
        ]
    }
    return (
        <div style={{height: "60vh"}}>
            <Line data={tableData}  options={{maintainAspectRatio: false}}/>
        </div>
    )
}