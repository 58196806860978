import React from 'react';
import Paper from "@material-ui/core/Paper";
import PendingUsers from './PendingUsers';
import Maintainers from './Maintainers';
import Admins from './Admins';

const paperStyle = {
    paddingTop: "10px",
    paddingBottom: "30px",
    paddingLeft: "10px",
    paddingRight: "10px"
}

export default function UserSettingsPage(props) {
    return (
        <div>
            <Paper style={paperStyle} elevation={3}>
                <PendingUsers/>
            </Paper>
            <Paper
                style={{
                ...paperStyle,
                marginTop: 30
            }}
                elevation={3}>
                <Maintainers/>
            </Paper>
            <Paper
                style={{
                ...paperStyle,
                marginTop: 30
            }}
                elevation={3}>
                <Admins auth={props.auth}/>
            </Paper>
        </div>

    )
}
