import React from 'react'


import SelectDrone from './SelectDrone'
import DroneForm from './DroneForm'
import DroneDetails from './DroneDetails'
import DroneDeploy from './DroneDeploy'

export default function DroneSetupSteps(props) {
const {droneDetails, setDroneDetails, setIsAllowedContinue, step} = props
    const displayElement =(step => {
        switch (step) {
            case 0:
                return(<SelectDrone droneDetails={droneDetails} setDroneDetails={setDroneDetails} setIsAllowedContinue={setIsAllowedContinue}/>)
            case 1:
                return(<DroneForm droneDetails={droneDetails} setDroneDetails={setDroneDetails} setIsAllowedContinue={setIsAllowedContinue}/>)
            case 2:
                return(<DroneDetails droneDetails={droneDetails} setIsAllowedContinue={setIsAllowedContinue}/>)
            case 3:
                return(<DroneDeploy droneDetails={droneDetails} setIsAllowedContinue={setIsAllowedContinue}/>)
            default:
                break;
        }
    })

    return (
        <div>
            {displayElement(step)}
        </div>
    )
}
