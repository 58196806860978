import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import app from '../../base';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function MiniWidgets() {
  const classes = useStyles();
  const [alertNum, setAlertNum] = useState("Loading...")
  const [offlineNum, setOfflineNum] = useState("Loading...")
  const [testingNum, setTestingNum] = useState("Loading...")

  useEffect(() => {
      const db = app.firestore()
      const unsubscribe = db.collection("droneStatus").orderBy("timestamp", "desc").limit(1).onSnapshot(snapshot => {
          setOfflineNum(0)
          setAlertNum(0)
        snapshot.forEach(doc => {
          const dayRecord = doc.data()
          let newestDate = 0
          for (const key in dayRecord) {
            if(!isNaN(key) && newestDate < key) {
              newestDate = key
            }
          }
            const dronesObj = dayRecord[newestDate].drones
            for (const key in dronesObj) {
                if (dronesObj.hasOwnProperty(key)) {
                    const element = dronesObj[key];
                    if(element === 1) {
                        setAlertNum(prev => prev + 1)
                    }
                    if(element === 2) {
                        setOfflineNum(prev => prev + 1)
                    }
                }
            }
        })
      })
      return () => {
          unsubscribe()
      }
  }, [setAlertNum, setOfflineNum])

  useEffect(() => {
    const db = app.firestore()
    const unsubscribe = db.collection("global").doc("dronesCurrentlyTesting").onSnapshot(doc => {
      const testingDrones = doc.data()
      setTestingNum(Object.keys(testingDrones).length)
    })
    return () => {
      unsubscribe()
    }
  }, [setTestingNum])

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
              <h1>Alert Drones&nbsp;<FontAwesomeIcon icon={faExclamationTriangle} style={{color: "orange"}}/></h1>
              <h2>{alertNum}</h2>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
              <h1>Offline Drones&nbsp;<FontAwesomeIcon icon={faExclamationCircle} style={{color: "red"}}/></h1>
              <h2>{offlineNum}</h2>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>
              <h1>Drones Currently Testing</h1>
              <h2>{testingNum}</h2>
          </Paper>
        </Grid>

      </Grid>
    </div>
  );
}